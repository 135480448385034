export default {
  methods: {
    calculateBarWidth(numLabels) {
      const minLabels = 6 // Minimum number of labels to start scaling bar width
      const maxLabels = 30 // Adjust as needed to control scaling upper limit
      const minBarWidth = 20
      const maxBarWidth = 30

      // Ensure numLabels falls within the expected range
      const effectiveLabels = Math.max(numLabels, minLabels)

      // Calculate scaled width
      let scaledWidth = minBarWidth
      if (effectiveLabels > minLabels) {
        const scaleRatio = (effectiveLabels - minLabels) / (maxLabels - minLabels)
        scaledWidth += (maxBarWidth - minBarWidth) * Math.min(scaleRatio, 1) // Ensure we don't exceed maxBarWidth
      }

      return scaledWidth.toFixed(2)
    },
  },
}
